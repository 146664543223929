import { Divider, Typography as T } from 'antd'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import BlockSale from '../components/BlockSale'
import Layout from '../components/Layout'
import MenuCategory from '../components/MenuCategory'
import SEO from '../components/SEO'
import { getImageInfo } from '../gatsby/NodeHelpers'
import './index.scss'

const BlogDetailPage = ({ location, data: { blogPost, saleBlock, blogBlock }, pageContext: { locale, tags } }: any) => {
  const blog = blogPost.data
  const imgUrl = blog.body[0].items[0].gallery_image.localFile.publicURL

  const blogImage = getImageInfo(blog.body)
  return (
    <Layout locale={locale} location={location}>
      <SEO
        location={location}
        locale={locale}
        type="article"
        keywords={blog.meta_keywords}
        title={blog.meta_title}
        description={blog.meta_description}
        properties={{ imgUrl }}
      />
      <MenuCategory type="blog" locale={locale} tags={tags} />
      <div className="container blog-detail">
        <T.Title level={3}>{blog.title.text}</T.Title>
        <T.Text type="secondary">Sunny Garden - {blogPost.last_publication_date}</T.Text>
        <Divider dashed={true} />
        <div className="sg-img">
          <Img
            style={{ width: 50 + '%', height: 50 + '%', margin: '0 auto' }}
            title={blogImage.caption}
            fluid={blogImage.fluid}
          />
        </div>
        <Divider dashed={true} />
        <RichText render={blog.content.raw} />
        <Divider dashed={true} />
        <BlockSale locale={locale} products={saleBlock.edges} blog={blogBlock.edges[0]} tags={tags} />
      </div>
    </Layout>
  )
}

export default BlogDetailPage

export const pageQuery = graphql`
  query BlogDetailBySlug($locale: String!, $uid: String!, $tags: [String!]!) {
    blogPost: prismicBlogPosts(lang: { eq: $locale }, uid: { eq: $uid }) {
      ...BlogDetailFragment
    }
    saleBlock: allPrismicShop(limit: 10, sort: { fields: [data___product_code, last_publication_date], order: DESC }, filter: { uid: { ne: $uid }, lang: { eq: $locale }, tags: { in: $tags } }) {
      edges {
        node {
          ...IndexSaleBlockFragment
        }
      }
    }
    blogBlock: allPrismicBlogPosts(
      limit: 1
      filter: { uid: { ne: $uid }, lang: { eq: $locale }, tags: { in: $tags } }
    ) {
      edges {
        node {
          ...IndexBlogBlockFragment
        }
      }
    }
  }
`
